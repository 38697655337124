// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
require('jquery')

import * as bootstrap from 'bootstrap';
import Chart from 'chart.js/auto';

global.Chart = Chart;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('turbolinks:load', () => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (el) {
    return new bootstrap.Tooltip(el)
  })

  const toastTriggerList = [].slice.call(document.querySelectorAll('.toast'))
  toastTriggerList.map(function (el) {
    return new bootstrap.Toast(el)
  })
});
